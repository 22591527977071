import { template as template_2a9926c5bb7b4d7fb03dc22b012a8e24 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2a9926c5bb7b4d7fb03dc22b012a8e24(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
