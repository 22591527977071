import { template as template_bba313f93d5e4ac9b4824651d36eed34 } from "@ember/template-compiler";
const SidebarSectionMessage = template_bba313f93d5e4ac9b4824651d36eed34(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
