import { template as template_280267823c37487f879983976d9a97ee } from "@ember/template-compiler";
import LightDarkImg from "discourse/components/light-dark-img";
const CategoryLogo = template_280267823c37487f879983976d9a97ee(`
  <div class="category-logo aspect-image">
    <LightDarkImg
      @lightImg={{@category.uploaded_logo}}
      @darkImg={{@category.uploaded_logo_dark}}
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryLogo;
