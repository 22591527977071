import { template as template_b6eb4a3de0b84897af8bd0cfc545bfcd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_b6eb4a3de0b84897af8bd0cfc545bfcd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
